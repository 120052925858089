import { Box, Flex, Switch, Text, useDisclosure } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa6";
import { IoLockClosedOutline } from "react-icons/io5";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}
export function PrivacyAccount(props: Props) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      {isOpen && (
        <>
          <Box
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            rounded="10px"
            bg="#171717"
            zIndex="10"
            py="6"
            px="4"
            fontFamily="inter"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center" mb="8">
              <FaChevronLeft cursor="pointer" onClick={onClose} />
              <Text fontWeight="300" fontSize="14px">
                {t("Private.buttonText")}
              </Text>

              <FaChevronLeft opacity="0" />
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="300" fontSize="16px">
                {t("Private.settingsTitle")}
              </Text>
              <Switch
                w="45px"
                size="lg"
                isChecked={props.isChecked}
                rounded="12px"
                colorScheme="blue"
                onChange={props.onChange}
                boxShadow="0px 10px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
              />
            </Flex>

            <Text
              fontWeight="300"
              mt="4"
              opacity=".8"
              fontSize="12.5px"
              letterSpacing=".3px"
              maxW="97%"
            >
              {t("Private.settingsInfo")}
            </Text>
          </Box>
          <Box
            onClick={onClose}
            position="fixed"
            left="0"
            top="0"
            bottom="0"
            right="0"
            zIndex="8"
          />
        </>
      )}

      <Box
        mb="16px"
        rounded="5px"
        display="flex"
        alignItems="center"
        bg="#1F1F1F"
        textAlign="center"
        onClick={onOpen}
        cursor="pointer"
      >
        <Box
          w="18%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="20px"
        >
          <IoLockClosedOutline color="#fff" />
        </Box>
        <Box
          w="90%"
          mx="auto"
          h="50px"
          display="flex"
          alignItems="center"
          justifyContent="start"
          pl="15px"
        >
          <Text
            color="white"
            fontWeight="300"
            fontSize="16px"
            fontFamily="inter"
          >
            {t("Private.buttonText")}
          </Text>
        </Box>
      </Box>
    </>
  );
}
