import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const language = localStorage.getItem("language");

let lang = language || "de"; // Default to English instead of German

i18n.use(initReactI18next).init({
  fallbackLng: "de", // Fallback language is now English
  lng: lang,
  interpolation: { escapeValue: false },
  resources: {
    en: {
      translation: {
        yourPersonalClinics: `${"Your personal <br/> Clinics"}`,
        add: "Add",
        start: "START",
        login: "Login",
        addMore: "Add more",
        contact: "Contact",
        done: "DONE",
        upload: "Upload",
        document: "Files",
        deleteProfile: "Delete profile",
        editProfile: "Edit profile",
        emergency: "EMERGENCY",
        medicall: "MEDICAL RECORDS",
        patientRecord: "PATIENT RECORD",
        profile: "Profile",
        photo: "Images",
        medicalRecord: "MEDICAL RECORD",
        signOut: "Sign out",
        changePassword: "Change password",
        create: "Create",
        cropAvatar: "Edit Profile Picture",
        saveAvatar: "Save Profile Picture",
        fileUpload: "Posting...",
        CREATENew: "CREATE NEW PASSWORD",
        saveContact: "Save our contacts",
        updateAvatar: "Avatar updated successfully",
        selectedFile: "Selected file deleted successfully",
        wrongEmail: "you entered the wrong email",
        filedToSend: "Failed to send data, please try again",
        emailNotForForgotPassword: "Email not for forgot password",
        thereIsNoSuch: "There is no such thing active account",
        allergies: "ALLERGIES",
        dateOfBrith: "DATE OF BIRTH",
        emergencyContact: "EMERGENCY CONTACT",
        firstName: "FIRST NAME",
        lastName: "LAST NAME",
        importTantInfo: "IMPORTANT INFORMATION / OR MEDICATIONS",
        diagnosen: "DIAGNOSIS",
        operationen: "OPERATIONS",
        medicamentPlan: "MEDICATION PLAN",
        nebenDiagnosen: "OCCUPATION",
        location: "ADDRESS",
        gender: {
          title: "Gender",
          male: "Male",
          female: "Female",
        },

        guestLogin: "Guest Login",
        yourName: "Your name",
        enterEmail: "Enter email",
        desc: "Enter the password we sent to your email address ",
        next: "Next",
        register: "Register",
        fileAnAll: "Fill in all fields, they are both required",
        thisIsCode: "This code is wrong",
        wellDone: "Well Done",
        descWellDone: "New password created successfully",
        sayHello: "Say Hello to your Personal HealthCard ",
        uploadTitle: "Done",
        yourRegistration: "Your Registration was successful ",
        resetYourPasword: "Reset Your Password",
        deleteFile: "Delete file",
        descReset:
          "Password reset link sent to your registered email address. Please check your inbox or spam folder",
        fileABig: "The file is too big",
        checkTheUserName: "Mistake!!! Check the username or that password",
        clickIfYouDontRememberYourPassword:
          "Click if you don’t remember your password",
        welcome: "Welcome back",
        welcomeToYour: "Welcome",
        wrongEmailAdress: "Wrong email address",
        letsStartWith: "Registration",
        requiredFields: "Required fields",
        passwordDoesNot: "Password does not match",
        fieldPasswordRequired: "Field password required",
        passwordsMustMatch: "passwords must match",
        takenEmail: "* The e-mail address you entered is already taken",
        no: "NO",
        yes: "YES",
        SuccessDelete: "Your profile has been deleted",
        deleteProfileConfirm:
          "Do you want to delete your medicalswitzerland Profile",
        deleteSuccessFile: "Your profile has been successfully deleted",
        delete: "Delete",

        Terms1: "By signing up, you agree to our",
        TermsLink1: "Terms of Use",
        Terms2: "and",
        TermsLink2: "Privacy Policy",
        Vaccination: {
          title: "Vaccination",
          name: {
            title: "Vaccine Name - Manufacturer",
            placeholder: "Vaccine:",
          },
          date: {
            title: "Date: [Date of the vaccination]",
            placeholder: "Date",
          },
          required: {
            title: "Booster required? Yes/No",
            placeholder: "Booster required?:",
            yes: "Yes",
            no: "No",
          },
          batchNo: {
            title: "Batch No.:",
            placeholder: "Batch No.:",
          },
          buttons: {
            addMore: "Add more vaccination",
            add: "Add",
          },
        },
        BloodTypes: {
          title: "Blood Type",
          unknown: "Unknown",
        },
        RhFactor: {
          title: "Rh Factor",
          positive: "Positive (+)",
          negative: "Negative (-)",
          unknown: "Unknown",
        },
        LocationFields: {
          street: "Street Number/Name:",
          village: "Village:",
          parish: "Parish:",
          district: "District:",
          city: "City:",
          region: "Region/Province:",
          country: "Country:",
          nearest: "Nearest Health Facilities:",
        },
        Private: {
          title: "It's a private account",
          desc: "log in to see more data",
          buttonText: "Account privacy",
          settingsTitle: "Private account",
          private: "Private",
          public: "Public",
          settingsInfo:
            "When your account is private, nobody can see your page unless you provide them with the password.\n Public account: When your account is public, your emergency page can be seen by anyone, on or off the health card platform, even if they don’t have a health card profile.",
          toPrivate: {
            title: "Switch to Private Account?",
            buttonName: "Switch to private",
            info: "When your account is private, only you can see your profile and information. Your medical details, and personal data are fully protected. Access to your emergency page and medical records will only be possible with your password, keeping you in complete control.",
          },
          toPublic: {
            title: "Switch to Public Account?",
            buttonName: "Switch to public",
            info: "When your account is public, your emergency page can be seen by anyone, on or off the health card platform, even if they don’t have a health card profile.",
          },
        },
      },
    },
    de: {
      translation: {
        yourPersonalClinics: `${"Ihre persönlichen <br/> Kliniken"}`,
        start: "STARTEN",
        add: "Hinzufügen",
        login: "ANMELDUNG",
        addMore: "Mehr hinzufügen",
        contact: "Kontakt",
        done: "Speichern",
        upload: "Hochladen",
        document: "Berichte",
        deleteProfile: "Profil löschen",
        editProfile: "Bearbeiten",
        emergency: "Notfall",
        medicall: "Akte",
        patientRecord: "PATIENTENAKTE",
        profile: "Profil",
        photo: "Bilder",
        medicalRecord: "PATIENTENAKTE",
        signOut: "Ausloggen",
        changePassword: "Kennwort ändern",
        create: "Erstellen",
        cropAvatar: "Profilbild bearbeiten",
        saveAvatar: "Profilbild speichern",
        fileUpload: "Wird hochgeladen...",
        CREATENew: "NEUES PASSWORT FESTLEGEN",
        saveContact: "Kontakt speichern",
        updateAvatar: "Profilbild erfolgreich aktualisiert",
        selectedFile: "Ausgewählte Datei erfolgreich gelöscht",
        wrongEmail: "Sie haben die falsche E-Mail-Adresse eingegeben",
        filedToSend:
          "Daten konnten nicht gesendet werden, bitte versuchen Sie es erneut",
        emailNotForForgotPassword: "E-Mail nicht für vergessenes Passwort",
        thereIsNoSuch: "Es gibt kein aktives Konto",
        allergies: "ALLERGIE",
        dateOfBrith: "GEBURTSDATUM",
        emergencyContact: "NOTFALLKONTAKT",
        firstName: "VORNAME",
        lastName: "NACHNAME",
        importTantInfo: "BESONDERHEITEN",
        diagnosen: "DIAGNOSEN",
        operationen: "OPERATIONEN",
        medicamentPlan: "MEDIKAMENTEN PLAN",
        nebenDiagnosen: "BERUF",
        location: "ADRESSE",
        gender: {
          title: "Geschlecht",
          male: "Männlich",
          female: "Weiblich",
        },
        guestLogin: "Gast-Login",
        yourName: "Ihren Namen",
        enterEmail: "Email eingeben",
        desc: "Geben Sie das Passwort ein, das wir an Ihre E-Mail-Adresse gesendet haben",
        next: "Weiter",
        register: "Registrieren",
        fileAnAll: "Füllen Sie alle Felder aus, beide sind Pflichtfelder",
        thisIsCode: "Dieser Code ist falsch",
        wellDone: "Gut gemacht",
        descWellDone: "Neues Passwort erfolgreich erstellt",
        sayHello: "Begrüßen Sie Ihre Persönliche GesundheitsCard",
        uploadTitle: "Datei erfolgreich hochgeladen",
        yourRegistration: "Your Registration was successful",
        resetYourPasword: "Ihre Anfrage war erfolgreich",
        deleteFile: "Datei löschen",
        descReset:
          "Link zum Zurücksetzen des Passworts an Ihre registrierte E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang oder Spam-Ordner",
        fileABig: "Die Datei ist zu groß",
        checkTheUserName:
          "Überprüfen Sie den Benutzernamen und geben Sie das korrekte Passwort ein.",
        clickIfYouDontRememberYourPassword:
          "Klicken Sie, wenn Sie sich nicht an Ihr Passwort erinnern",
        welcome: "Willkommen zurück",
        welcomeToYour: "Willkommen",
        wrongEmailAdress: "falsche E-Mail Adresse",
        letsStartWith: "Registrieren",
        requiredFields: "Benötigte Felder",
        passwordDoesNot: "Passwort stimmt nicht überein",
        fieldPasswordRequired: "Feldpasswort erforderlich",
        passwordsMustMatch: "Die Passwörter müssen übereinstimmen",
        takenEmail: "* Die eingegebene e mail Adresse ist bereits vergeben.",
        no: "Nein",
        yes: "Ja",
        SuccessDelete:
          "Ihr Profil und alle dazugehörigen Daten und Bilder wurden gelöscht.",
        deleteProfileConfirm:
          "Möchten Sie Ihr medicalswitzerland Profil löschen",
        deleteSuccessFile: "Ihr Profil wurde erfolgreich gelöscht",
        delete: "Löschen",

        Terms1: "Mit Ihrer Anmeldung stimmen Sie unseren",
        TermsLink1: "Nutzungsbedingungen",
        Terms2: "und",
        TermsLink2: "Datenschutzrichtlinie",
        Vaccination: {
          title: "Impfungen",
          name: {
            title: "Impfstoffname - Hersteller",
            placeholder: "Impfungen:",
          },
          date: {
            title: "Datum: [Datum]",
            placeholder: "Datum",
          },
          required: {
            title: "Auffrischung erforderlich? Ja/Nein",
            placeholder: "Auffrischung erforderlich?:",
            yes: "Ja",
            no: "Nein",
          },
          batchNo: {
            title: "Charge Nr.:",
            placeholder: "Charge Nr.:",
          },
          buttons: {
            addMore: "Weitere Impfungen hinzufügen",
            add: "hinzufügen",
          },
        },
        BloodTypes: {
          title: "Blutgruppe",
          unknown: "Unbekannt",
        },
        RhFactor: {
          title: "Rhesusfaktor",
          positive: "Positiv (+)",
          negative: "Negativ (-)",
          unknown: "Unbekannt",
        },
        LocationFields: {
          street: "Hausnummer/Name:",
          village: "Dorf:",
          parish: "Gemeinde:",
          district: "Bezirk:",
          city: "Stadt:",
          region: "Region/Provinz:",
          country: "Land:",
          nearest: "Nächstgelegene Gesundheitseinrichtungen:",
        },
        Private: {
          title: "Es ist ein Privatkonto",
          desc: "Einloggen, um mehr Daten zu sehen",
          buttonText: "Kontodatenschutz",
          private: "Privat",
          public: "Öffentlich",
          settingsTitle: "Kontoeinstellungen",
          settingsInfo:
            "Privates Konto, Wenn dein Konto privat ist, kann niemand deine Seite sehen, es sei denn, du gibst ihnen das Passwort. Öffentliches Konto: Wenn dein Konto öffentlich ist, kann deine Notfallseite von jedem gesehen werden, sowohl auf als auch außerhalb der Health-Card-Plattform, selbst wenn sie kein Health-Card-Profil haben.",
          toPrivate: {
            title: "Zum Privatkonto wechseln?",
            buttonName: "Zu privat wechseln",
            info: "Wenn Ihr Konto privat ist, können nur Sie Ihr Profil und Ihre Informationen sehen. Ihre medizinischen Details und persönlichen Daten sind vollständig geschützt. Der Zugang zu Ihrer Notfallseite und Ihren medizinischen Unterlagen ist nur mit Ihrem Passwort möglich, so dass Sie die volle Kontrolle behalten.",
          },
          toPublic: {
            title: "Zum öffentlichen Konto wechseln?",
            buttonName: "Wechsel zur Öffentlichkeit",
            info: "Wenn Ihr Konto öffentlich ist, kann Ihre Notfallseite von allen Personen auf der Gesundheitskarten-Plattform oder außerhalb gesehen werden, auch wenn sie kein Gesundheitskarten-Profil haben.",
          },
        },
      },
    },
    fr: {
      translation: {
        yourPersonalClinics: `${"Vos cliniques <br/> personnelles"}`,
        start: "DÉPART",
        add: "Ajouter",
        login: "Se connecter",
        addMore: "Ajouter plus",
        contact: "Contact",
        done: "Enregistrer",
        upload: "Télécharger",
        document: "Fichiers",
        deleteProfile: "Supprimer le profil",
        editProfile: "Modifier",
        emergency: "URGENCES",
        medicall: "DOSSIEURS MÉDICAUX",
        patientRecord: "Dossier du patient",
        profile: "Profil",
        photo: "Images",
        medicalRecord: "Dossier médical",
        signOut: "Se déconnecter",
        changePassword: "Changer le mot de passe",
        create: "Créer",
        cropAvatar: "Modifier la photo de profil",
        saveAvatar: "Enregistrer la photo de profil",
        fileUpload: "Téléchargement en cours...",
        CREATENew: "CRÉER UN NOUVEAU MOT DE PASSE",
        saveContact: "Enregistrer nos contacts",
        updateAvatar: "Avatar mis à jour avec succès",
        selectedFile: "Fichier sélectionné supprimé avec succès",
        wrongEmail: "Vous avez entré une adresse e-mail incorrecte",
        filedToSend: "Échec de l'envoi des données, veuillez réessayer",
        emailNotForForgotPassword:
          "L'email n'est pas valable pour la récupération du mot de passe",
        thereIsNoSuch: "Il n'y a pas de compte actif correspondant",
        allergies: "ALLERGIES",
        dateOfBrith: "DATE DE NAISSANCE",
        emergencyContact: "CONTACT D'URGENCE",
        firstName: "PRÉNOM",
        lastName: "NOM DE FAMILLE",
        importTantInfo: "INFORMATIONS IMPORTANTES / MÉDICAMENTS",
        diagnosen: "DIAGNOSTICS",
        operationen: "OPÉRATIONS",
        medicamentPlan: "PLAN MÉDICAMENTEUX",
        nebenDiagnosen: "OCCUPATION",
        location: "ADRESSE",
        gender: {
          title: "Genre",
          male: "Mâle",
          female: "Femelle",
        },

        guestLogin: "Connexion invité",
        yourName: "Votre nom",
        enterEmail: "Entrez l'e-mail",
        desc: "Entrez le mot de passe que nous avons envoyé à votre adresse e-mail",
        next: "Suivant",
        register: "S'inscrire",
        fileAnAll: "Veuillez remplir tous les champs, ils sont obligatoires",
        thisIsCode: "Ce code est incorrect",
        wellDone: "Bien joué",
        descWellDone: "Nouveau mot de passe créé avec succès",
        sayHello: "Dites bonjour à votre carte de santé personnelle",
        uploadTitle: "Fichier téléchargé avec succès",
        yourRegistration: "Votre inscription a réussi",
        resetYourPasword: "Réinitialiser votre mot de passe",
        deleteFile: "Supprimer le fichier",
        descReset:
          "Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail enregistrée. Veuillez vérifier votre boîte de réception ou votre dossier de courrier indésirable.",
        fileABig: "Le fichier est trop grand",
        checkTheUserName:
          "Erreur ! Vérifiez le nom d'utilisateur ou le mot de passe",
        clickIfYouDontRememberYourPassword:
          "Cliquez si vous ne vous souvenez plus de votre mot de passe",
        welcome: "Bienvenue à nouveau",
        welcomeToYour: "Bienvenue",
        wrongEmailAdress: "Adresse e-mail incorrecte",
        letsStartWith: "Inscription",
        requiredFields: "Champs requis",
        passwordDoesNot: "Le mot de passe ne correspond pas",
        fieldPasswordRequired: "Champ de mot de passe requis",
        passwordsMustMatch: "Les mots de passe doivent correspondre",
        takenEmail: "* L'adresse e-mail que vous avez entrée est déjà prise",
        no: "Non",
        yes: "Oui",
        SuccessDelete: "Votre profil a été supprimé",
        deleteProfileConfirm:
          "Souhaitez-vous supprimer votre profil medicalswitzerland ?",
        deleteSuccessFile: "Votre profil a été supprimé avec succès",
        delete: "Supprimer",

        Terms1: "En vous inscrivant, vous acceptez nos",
        TermsLink1: "Conditions d'utilisation",
        Terms2: "et",
        TermsLink2: "politique de confidentialité",
        Vaccination: {
          title: "Vaccinations ",
          name: {
            title: "Nom du vaccin - Manufacturer",
            placeholder: "Vaccination:",
          },
          date: {
            title: "Date : [Date de la vaccination]",
            placeholder: "Date",
          },
          required: {
            title: "Rappel nécessaire?: Oui/Non",
            placeholder: "Rappel nécessaire?:",
            yes: "Oui",
            no: "Non",
          },
          batchNo: {
            title: "Numéro de lot :",
            placeholder: "Numéro de lot :",
          },
          buttons: {
            addMore: "Ajouter des vaccins",
            add: "Ajouter",
          },
        },
        BloodTypes: {
          title: "Groupe Sanguin",
          unknown: "Inconnu",
        },
        RhFactor: {
          title: "Facteur Rh",
          positive: "Positif (+)",
          negative: "Négatif (-)",
          unknown: "Inconnu",
        },
        LocationFields: {
          street: "Numéro/Nom de la rue:",
          village: "Village:",
          parish: "Paroisse:",
          district: "District:",
          city: "Ville:",
          region: "Région/Province:",
          country: "Pays:",
          nearest: "Établissements de santé les plus proches:",
        },
        Private: {
          title: "Il s'agit d'un compte privé",
          desc: "se connecter pour voir plus de données",
          buttonText: "Confidentialité du compte",
          settingsTitle: "Compte privé",
          private: "Privé",
          public: "Public",
          settingsInfo:
            "Lorsque votre compte est privé, personne ne peut voir votre page à moins que vous ne lui fournissiez le mot de passe. Compte public : Lorsque votre compte est public, votre page d'urgence peut être vue par n'importe qui, sur la plate-forme de la carte de santé ou en dehors, même s'il n'a pas de profil de carte de santé.",
          toPrivate: {
            title: "Passer en compte privé?",
            buttonName: "Passer en privé",
            info: "Lorsque votre compte est privé, vous êtes le seul à pouvoir voir votre profil et vos informations. Vos données médicales et personnelles sont entièrement protégées. L’accès à votre page d’urgence et à vos dossiers médicaux ne sera possible qu’avec votre mot de passe, vous assurant un contrôle total.",
          },
          toPublic: {
            title: "Passer au compte public ?",
            buttonName: "Passer au public",
            info: "Lorsque votre compte est public, votre page d'urgence peut être vue par n'importe qui, sur la plateforme de la carte de santé ou en dehors, même s'il n'a pas de profil de carte de santé.",
          },
        },
      },
    },
  },
});

export default i18n;
