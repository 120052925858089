import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Switch,
  Text,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa6";
import { IoLockClosedOutline } from "react-icons/io5";

interface Props {
  setSwitch: Dispatch<SetStateAction<boolean>>;
  isSwitch: boolean;
  onsubmit: (checked: boolean) => void;
}
export function PrivacyAccount(props: Props) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onClose: onCloseDrawer,
    onOpen: onOpenDrawer,
  } = useDisclosure();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onOpenDrawer();
    props.setSwitch(event.target.checked);
  };

  const onCloseDrawerModal = () => {
    onCloseDrawer();
    props.setSwitch((prev) => !prev);
  };

  const onSubmit = () => {
    props.onsubmit(props.isSwitch);
    onCloseDrawer();
  };
  return (
    <>
      {isOpen && (
        <>
          <Box
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            rounded="10px"
            bg="#171717"
            zIndex="10"
            py="6"
            px="4"
            fontFamily="inter"
            color="white"
          >
            <Flex justifyContent="space-between" alignItems="center" mb="8">
              <FaChevronLeft cursor="pointer" onClick={onClose} />
              <Text fontWeight="300" fontSize="14px">
                {t("Private.buttonText")}
              </Text>

              <FaChevronLeft opacity="0" />
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="300" fontSize="16px">
                {t("Private.settingsTitle")}
              </Text>
              <Switch
                w="45px"
                size="lg"
                isChecked={props.isSwitch}
                rounded="12px"
                colorScheme="blue"
                onChange={onChange}
                boxShadow="0px 10px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)"
              />
            </Flex>

            <Text
              fontWeight="300"
              mt="4"
              opacity=".8"
              fontSize="12.5px"
              letterSpacing=".3px"
              maxW="97%"
            >
              {t("Private.settingsInfo")}
            </Text>
          </Box>
          <Box
            onClick={onClose}
            position="fixed"
            left="0"
            top="0"
            bottom="0"
            right="0"
            zIndex="8"
          />
        </>
      )}

      <Box
        mb="16px"
        rounded="5px"
        display="flex"
        alignItems="center"
        bg="#1F1F1F"
        textAlign="center"
        onClick={onOpen}
        cursor="pointer"
      >
        <Box
          w="18%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="20px"
        >
          <IoLockClosedOutline color="#fff" />
        </Box>
        <Box
          w="90%"
          mx="auto"
          h="50px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pl="15px"
          gap="2"
        >
          <Text
            color="white"
            fontWeight="300"
            fontSize="16px"
            fontFamily="inter"
          >
            {t("Private.buttonText")}
          </Text>

          <Text
            color="white"
            fontWeight="300"
            opacity=".6"
            fontSize="12px"
            fontFamily="inter"
            pr="4"
          >
            {props.isSwitch ? t("Private.private") : t("Private.public")}
          </Text>
        </Box>
      </Box>

      <Drawer
        isOpen={isOpenDrawer}
        onClose={onCloseDrawerModal}
        placement="bottom"
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent borderTopRadius="12px" bg="#272727" pt="6" pb="10">
          <Container>
            <Text
              color="#FFFFFF"
              fontSize="14px"
              fontWeight="600"
              fontFamily="inter"
              mb="6"
              textAlign="center"
            >
              {props.isSwitch
                ? t("Private.toPrivate.title")
                : t("Private.toPublic.title")}
            </Text>
            <Box>
              <Text
                opacity=".8"
                color="#fff"
                fontSize="14px"
                fontWeight="400"
                mb="3px"
              >
                {props.isSwitch
                  ? t("Private.toPrivate.info")
                  : t("Private.toPublic.info")}
              </Text>
            </Box>

            <Box mb="3"></Box>

            <Button
              mt="41px"
              bg="#0B6CFF"
              fontSize="16px"
              fontFamily="inter"
              rounded="7px"
              h="37px"
              w="100%"
              color="white"
              onClick={onSubmit}
            >
              {props.isSwitch
                ? t("Private.toPrivate.buttonName")
                : t("Private.toPublic.buttonName")}
            </Button>
          </Container>
        </DrawerContent>
      </Drawer>
    </>
  );
}
