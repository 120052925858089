import { Flex, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { IoLockClosedOutline } from "react-icons/io5";

export function PrivateComponent() {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="#fff"
      pt="8"
      gap="2"
    >
      <Flex
        border="1px solid #fff"
        rounded="50%"
        w="16"
        h="16"
        justifyContent="center"
        alignItems="center"
        opacity=".9"
      >
        <IoLockClosedOutline fontSize="34px" />
      </Flex>
      <Text fontWeight="600" fontSize="20px">
        {t("Private.title")}
      </Text>
      <Text opacity=".7" fontSize="12px">
        {t("Private.desc")}
      </Text>
    </Flex>
  );
}
